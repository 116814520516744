<template>
    <div class="Index">
    <div class="mariad">
      <div v-if="type == '0'">当前状态:<span class="colorer">关机</span></div>
      <div v-else-if="type == '1'">
        当前状态:<span class="colorer1">开机</span>
      </div>
      <div v-else-if="type == '2'">
        当前状态:<span class="colorer2">设备不在线</span>
      </div>
    </div>
    <!-- end -->
    <div>
      <van-grid>
        <van-grid-item icon="play-circle-o" text="开机" @click="poster('1')" />
        <van-grid-item icon="stop-circle-o" text="关机" @click="poster('0')" />
        <van-grid-item icon="close" text="强制重启" @click="poster('2')" />
        <van-grid-item icon="warning-o" text="查询状态" @click="aaddaa" />
        <van-grid-item icon="underway" text="充电宝" @click="borrow" />
        <van-grid-item icon="revoke" text="退出登录" @click="tuoClick" />
      </van-grid>
    </div>
  </div>
</template>

<script>
import { httpPOst,portable } from "@/request/api"; // 导入api接口
import { removeItem } from "@/utils/storage";
import { onBeforeMount, reactive, ref, toRefs,computed } from "vue";
import { useStore } from 'vuex';
import { Toast } from "vant";
import { useRouter } from "vue-router";
export default {
    setup(){
      const store = useStore();
      let type = ref('');
      let aaaa = ref('');
      const router = useRouter(); //路由
      let userData = computed(() => store.state.user.token) 
      
      onBeforeMount(()=>{
        inst();
      })
      // inst
      const inst = ()=>{
        let data = {
          sgdz_account:userData.value.user,
          sgdz_password:userData.value.password,
          device_name:userData.value.device_name == "" ? "家":userData.value.device_name,
          value:"11",
        }
        httpPOst(data).then(res=>{
          type.value = res.data.status;
        })
      };
      const aaddaa = ()=>{
        location.reload();
      };
      const poster = (e) =>{
        let data = {
          sgdz_account:userData.value.user,
          sgdz_password:userData.value.password,
          device_name:userData.value.device_name == "" ? "家":userData.value.device_name,
          value:e,
        }
         httpPOst(data).then((res) => {
            aaaa.value = res;
            Toast.success("响应成功");
          });

      }
      const borrow = ()=>{
        Toast.loading({
          message: '加载中...',
          forbidClick: true,
        });
          let data = {
        ieim: "VTSC8532106000001",
        ca_state: "2_2_2_2_2_2",
        type: "16",
      };
        portable(data).then((res) => {
          if(res.data.code != 1000){
            Toast.fail(res.data.message);
            return
          }
          Toast.success(res.data.message);
        });
      }
      const tuoClick =()=>{
        removeItem('store');
        router.push('/login');
      }
      return{
        type,userData,aaaa,
        inst,aaddaa,poster,borrow,tuoClick
      }

    }
}
</script>

<style scoped>
.mariad {
  margin: 20px;
  box-sizing: border-box;
}
.colorer {
  color: brown;
}
.colorer1 {
  color: chartreuse;
}
.colorer2{
  color: red;
}
</style>