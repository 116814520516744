// 在http.js中引入axios
import axios from "axios"; // 引入axios
// import QS from 'qs'; // 引入qs模块，用来序列化post类型的数据，后面会提到
// import router from "@/router";

// 环境的切换
if (process.env.NODE_ENV == "development") {
  //开发环境
  axios.defaults.baseURL = "";
} else if (process.env.NODE_ENV == "debug") {
  axios.defaults.baseURL = "";
} else if (process.env.NODE_ENV == "production") {
  //生产环境
  axios.defaults.baseURL = "";
}

axios.defaults.timeout = 10000;
axios.defaults.headers.post["Content-Type"] =
  "application/x-www-form-urlencoded;charset=UTF-8";
export default axios;
