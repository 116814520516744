import { createApp } from 'vue'
import App from './App.vue'
import {router} from "@/router";
import store from "./store";
import Vant from "vant"
import 'vant/lib/index.css'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
 
 
const app = createApp(App)
app.use(router)
app.use(Vant)
app.use(store)
app.use(ElementPlus)
 // ---------------------- 路由拦截 方法 -----------------------------------//
 router.beforeEach((to, from, next) => {
    if (to.matched.some(res => res.meta.isLogin)) { //判断是否需要登录
      if (store.state.user.token) { //如果已经登陆就放行
        return next()
      } else {
        // 前往登录页面
        next({
          path: "/login",
          //保存路径登录完要返回这个页面
          query: {
            redirect: to.fullPath
          }
        });
      }
    } else {
      next()
    }
  });
app.mount('#app')