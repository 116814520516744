import { createRouter,createWebHistory,createWebHashHistory } from "vue-router";
import routes from "@/router/routes";
 
const router = new createRouter({
    history:createWebHashHistory(),
    routes
})
 
export {
    router
}