import { getItem, setItem, } from "@/utils/storage";
const user = {
    state: {
        token:getItem("store")
    },
    mutations: {
        __login(state, token) {
            console.log(token)
            state.token = token;
            setItem("store", state.token);
        }
    }
}
export default user
