import Index from "@/views/index";
var routes = [
    {
        path:"/",
        name:"Index",
        component:Index,
        meta:{isLogin: true}
    },
    {
        path:"/login",
        name:"login",
        component:() => import('@/views/Login'),
        meta:{isLogin: false}
    }
]

export default routes