/**
 * api接口统一管理
 */
 import request from "@/request/http";

 export function httpPOst(data) {
    return request({
      url: "/api/Esp_Api_new.php",
      method: "POST",
      data,
    });
  }
  // 充电宝接口
  export function portable(data) {
    return request({
      url: "https://test.vdianba.com/iot/device/sendDisableDevice",
      method: "POST",
      data,
    });
  }